<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.sms_provider_name }}</div>

      <el-button
        @click="openModal = true"
        class="editBtn"
        :disabled="!can('system.sms-providers.write')"
        >{{ __("Edit Sms Provider") }}</el-button
      >
      <el-button
        @click="handleDelete(contentForm)"
        class="deleteBtn"
        :disabled="!can('system.sms-providers.write')"
        >{{ __("Delete") }}</el-button
      >
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div class="sms-provider-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Sms provider')" :contentId="id" />
          </el-col>
        </el-row>

        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            style="overflow-x: hidden;"
          >
            <el-row type="flex" :gutter="20">
              <el-col :span="12" :offset="6">
                <el-tabs
                  v-model="activeTab"
                  class="tabs"
                  style="font-size: 1.2em;"
                >
                  <el-tab-pane :label="__('Basic')" name="basic">
                    <el-form-item prop="sms_provider_name" :label="__('Name')">
                      <el-input
                        v-model="contentForm.sms_provider_name"
                      ></el-input>
                    </el-form-item>

                    <el-form-item prop="json_data.url" :label="__('URL')">
                      <el-input v-model="contentForm.json_data.url"></el-input>
                    </el-form-item>

                    <el-form-item
                      prop="json_data.http_method"
                      :label="__('HTTP method')"
                    >
                      <el-select
                        v-model="contentForm.json_data.http_method"
                        style="width: 100%;"
                        default-first-option
                        filterable
                      >
                        <el-option value="POST"></el-option>
                        <el-option value="GET"></el-option>
                        <el-option value="PUT"></el-option>
                        <el-option value="PATCH"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-tab-pane>

                  <el-tab-pane
                    :label="__('Request Parameters')"
                    name="request_parameters"
                  >
                    <template>
                      <parameter
                        :ref="'requestParam' + index + 'Ref'"
                        v-for="(param, index) in requestParams"
                        :key="'request_param_' + index"
                        :parameter="param"
                        :index="index"
                        :is-last="index === requestParams.length - 1"
                        @remove="removeRequestParameter(index)"
                        @update="updateRequestParameter"
                      ></parameter>
                      <el-button
                        style="margin-bottom: 34px"
                        @click="addRequestParameter()"
                        >{{ __("Add request parameter") }}</el-button
                      >
                    </template>
                  </el-tab-pane>

                  <el-tab-pane
                    :label="__('Response Parameters')"
                    name="response_parameters"
                  >
                    <template>
                      <parameter
                        :ref="'responseParam' + index + 'Ref'"
                        v-for="(param, index) in responseParams"
                        :key="'response_param_' + index"
                        type="response"
                        :parameter="param"
                        :index="index"
                        :is-last="index === responseParams.length - 1"
                        @remove="removeResponseParameter(index)"
                        @update="updateResponseParameter"
                      ></parameter>
                      <el-button
                        style="margin-bottom: 34px"
                        @click="addResponseParameter()"
                        >{{ __("Add response parameter") }}</el-button
                      >
                    </template>
                  </el-tab-pane>
                </el-tabs>
                <el-form-item>
                  <save-button
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="!can('system.sms-providers.write')"
                    :primaryKey="id"
                  />
                  <el-button @click="handleCancel" class="cancelBtn">{{
                    __("Cancel")
                  }}</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import Parameter from "@/views/manage/sms/components/parameter";
import _ from "lodash";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    Parameter,
    SaveButton
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  data() {
    return {
      isSubmitting: false,
      rules: {
        sms_provider_name: [
          {
            required: true,
            message: __("SMS provider name cannot be empty"),
            trigger: "blur"
          }
        ],
        json_data: {
          url: [
            {
              required: true,
              message: __("URL cannot be empty"),
              trigger: "blur"
            }
          ],
          http_method: [
            {
              required: true,
              message: __("Please select a HTTP method"),
              trigger: "change"
            }
          ]
        }
      },
      parametersValid: false,
      url: "",
      http_method: "POST",
      requestParams: [],
      responseParams: [],
      activeTab: "basic"
    };
  },

  methods: {
    ...mapActions("smsproviders", {
      createSmsProvider: "createSmsProvider",
      updateSmsProvider: "updateSmsProvider",
      deleteContentMethod: "deleteSmsProvider",
      undoDeleteContent: "undoDeleteSmsProvider"
      // sendTestSms: "sendTestSms"
    }),

    addRequestParameter() {
      this.requestParams.push({
        key: "",
        label: "",
        required: false,
        type: "sms_profile",
        default_data: "",
        test_data: "",
        path: "",
        is_editing: true
      });
    },

    removeRequestParameter(index) {
      this.requestParams.splice(index, 1);
    },

    updateRequestParameter(index, parameter) {
      this.$set(this.requestParams, index, parameter);
    },

    addResponseParameter() {
      this.responseParams.push({
        key: "",
        label: "",
        required: false,
        type: "response",
        default_data: "",
        test_data: "",
        path: "",
        is_editing: true
      });
    },

    removeResponseParameter(index) {
      this.responseParams.splice(index, 1);
    },

    updateResponseParameter(index, parameter) {
      this.$set(this.responseParams, index, parameter);
    },

    /**
     * update request and response params then submit if valid
     */
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        this.$set(this.contentForm.json_data, "request", {});
        this.parametersValid = true;
        for (const i in this.requestParams) {
          if (this.requestParams[i].is_editing && !this.requestParams[i].key) {
            this.parametersValid = false;
            this.$refs["requestParam" + i + "Ref"][0].submit();
          } else {
            this.$set(
              this.contentForm.json_data.request,
              this.requestParams[i].key,
              this.requestParams[i]
            );
          }
        }

        this.$set(this.contentForm.json_data, "response", {});
        for (const j in this.responseParams) {
          if (
            this.responseParams[j].is_editing &&
            !this.responseParams[j].key
          ) {
            this.parametersValid = false;
            this.$refs["responseParam" + j + "Ref"][0].submit();
          } else {
            this.$set(
              this.contentForm.json_data.response,
              this.responseParams[j].key,
              this.responseParams[j]
            );
          }
        }

        if (valid && this.parametersValid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createSmsProvider : this.updateSmsProvider;
          this.contentForm.is_enabled = 1;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Sms Provider added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Sms Provider updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    initializeSmsProvider() {
      this.isSubmitting = true;
      if (this.id !== -1) {
        this.requestParams = _.cloneDeep(
          Object.values(this.contentForm.json_data.request)
        );

        this.responseParams = _.cloneDeep(
          Object.values(this.contentForm.json_data.response)
        );
      } else {
        this.$set(this.contentForm.json_data, "request", {});
        this.$set(this.contentForm.json_data, "response", {});
        this.requestParams.splice(0, this.requestParams.length);
        this.responseParams.splice(0, this.responseParams.length);
      }
      this.isSubmitting = false;
    }
  },

  watch: {
    openModal(val) {
      if (val === true) {
        this.activeTab = "basic";
        this.initializeSmsProvider();
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;
  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

.editBtn {
  margin-top: 10px;
  height: 44px;
  background: $content-theme-color;
  border: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  &:hover {
    color: white;
    background: $content-theme-hover-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
