<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "SMSProvidersIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("smsproviders", {
      contents: state => state.smsProviders
    })
  },

  methods: {
    initContent() {
      return {
        sms_provider_name: "",
        json_data: {
          url: "",
          http_method: "",
          request: {},
          response: {}
        }
      };
    }
  },

  mounted() {
    this.contentType = "SMS Provider";
    this.primaryKey = "sms_provider_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
