<template>
  <div>
    <div class="flex justify-between">
      <div class="secondary-title">
        {{ type }}{{ __("Param") }}[{{ index }}]
      </div>
      <div>
        <el-button
          v-if="param.is_editing"
          icon="el-icon-check"
          circle
          @click="submit()"
        ></el-button>
        <el-button
          v-else
          icon="el-icon-edit"
          circle
          @click="edit()"
        ></el-button>
        <el-button icon="el-icon-delete" circle @click="remove()"></el-button>
      </div>
    </div>
    <el-form
      :rules="rules"
      :ref="'paramForm' + index"
      :model="param"
      v-if="param.is_editing"
    >
      <el-form-item prop="type" :label="__('Type')" v-if="type === 'request'">
        <el-select
          v-model="param.type"
          @change="updateParam"
          :placeholder="__('Select')"
          default-first-option
          style="width: 100%"
        >
          <el-option
            :label="__('Defined in SMS profiles')"
            value="sms_profile"
          ></el-option>
          <el-option :label="__('SMS content')" value="sms_content"></el-option>
          <el-option :label="__('Authentication')" value="auth"></el-option>
          <el-option
            :label="__('Path Parameter')"
            value="path_parameter"
          ></el-option>
          <el-option
            :label="__('Message Receiver')"
            value="sms_receiver"
          ></el-option>
          <el-option :label="__('Others')" value="others"></el-option>
        </el-select>
      </el-form-item>

      <el-row :gutter="20" v-if="param.type !== 'auth'">
        <el-col :span="12">
          <el-form-item prop="key" :label="__('Key')">
            <el-input v-model="param.key"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="label" :label="__('Display label')">
            <el-input
              v-model="param.label"
              :disabled="
                param.type === 'sms_content' || param.type === 'sms_receiver'
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-else>
        <el-col :span="12">
          <el-form-item prop="key" :label="__('Type')">
            <el-select
              v-model="param.key"
              :placeholder="__('Select')"
              default-first-option
            >
              <el-option
                v-for="item in authType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <input type="hidden" v-model="param.label" />
        </el-col>
      </el-row>

      <el-form-item prop="path" :label="__('Path')">
        <el-input v-model="param.path"></el-input>
      </el-form-item>

      <el-form-item
        v-if="
          type === 'request' &&
            !(param.type === 'sms_content' || param.type === 'sms_receiver')
        "
      >
        <el-checkbox v-model="param.required">{{ __("Required") }}</el-checkbox>
      </el-form-item>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="default_data" :label="__('Default data')">
            <el-input v-model="param.default_data"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="test_data" :label="__('Test data')">
            <el-input v-model="param.test_data"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-else class="py-2">
      {{ parameter }}
    </div>
    <hr v-if="!isLast" style="margin-top: 20px;" />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ParameterComponent",
  props: {
    parameter: {
      required: true
    },
    index: {
      required: true
    },
    isLast: {
      default: true,
      type: Boolean
    },
    type: {
      default: "request",
      type: String
    }
  },

  data() {
    return {
      rules: {
        key: [
          { required: true, trigger: "blur", message: __("Name is required") }
        ]
      },
      param: _.cloneDeep(this.parameter),
      authType: [
        {
          label: __("Five9"),
          value: "five9_SmsService"
        }
      ]
    };
  },

  methods: {
    remove() {
      this.$emit("remove", this.index);
    },

    edit() {
      this.$set(this.param, "is_editing", true);
    },

    submit() {
      this.$refs["paramForm" + this.index].validate(valid => {
        if (valid) {
          if (this.param.label == "") {
            this.param.label = this.param.key.replace(/ /g, "_");
          }

          if (this.param.path == "") {
            this.param.path = this.param.key;
          }

          this.$set(this.param, "is_editing", false);
          delete this.param.is_editing;
          this.$emit("update", this.index, this.param);
        }
      });
    },

    updateParam(type) {
      if (type === "sms_content" || type === "sms_receiver") {
        this.$set(this.param, "label", _.startCase(type));
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
@import "~@/styles/utils.scss";
</style>
